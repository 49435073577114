@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap");

$yellow: #e1a73b;

html {
  background-color: #000;
  color: #fff;
  font-size: 16px;
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.container-fluid {
  padding: 2rem;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.pb-0 {
  padding-bottom: 0 !important;
}

h1 {
  font-size: 2.5rem;
  margin: 0;
}

h2 {
  color: $yellow;
  font-size: 2rem;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin: 0;
  font-size: 2.4rem;
  letter-spacing: 1px;
  line-height: 1.4;
}

.car-icon {
  background-color: $yellow;
  width: 40px;
  height: 40px;
  padding: 0.75rem;
  border-radius: 0.3rem;
  margin-right: 1rem;
}

.ship-icon {
  background-color: $yellow;
  width: 40px;
  height: 40px;
  padding: 0.75rem;
  border-radius: 0.3rem;
  margin-right: 1rem;
}

.time {
  font-size: 5.5rem;
  font-weight: 700;
  background-color: $yellow;
  color: #000;
  padding: 0.75rem 1rem;
  border-radius: 0.3rem;
  position: absolute;
  top: 32px;
  right: 32px;
}

.tour-table {
  position: relative;
  z-index: 0;
  div:first-child {
    position: relative;
  }
}

.vessel-table {
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;
  background-color: #000;
  .container-fluid {
    > div {
      padding-top: 1.25rem;
      border-top: 2px dashed rgba(255,255,255,0.5);
      width: 100%;
    }
  }
  &.vessel-grid {
    position: relative;
    .container-fluid {
      > div {
        padding-top: 0;
        border-top: 0;
        width: 100%;
        &.vessel-list {
          display: block;
          padding-top: 2rem;
          > div {
            border-right: 0;
            padding: 1rem 0;
            margin: 0;
            display: flex;
            align-items: center;
            &:not(:last-of-type) {
              border-bottom: 2px dashed rgba(225, 167, 59, 0.5);
            }
            &.grid {
              display: flex;
              h2 {
                font-size: 3.5rem;
              }
              .column-1 {
                flex: 0 0 330px;
              }
              .column-2 {
                flex: 0 0 450px;
              }
            }
            p {
              display: flex;
              font-size: 4.5rem;
              &.boarding-time {
                flex: 0 0 470px;
                font-weight: 700;
              }
              &.departure-time {
                flex: 0 0 350px;
                font-weight: 700;
                margin-right: 0;
              }
              &.vessel-name {
                font-weight: 400;
              }
            }
          }
        }
      }
    }
    .vessel-title {
      border-right: 0;
      padding-right: 0;
      position: relative;
    }
    .time {
      margin-left: auto;
      top: 0;
      right: 0;
    }
  }
}

.vessel-title {
  display: flex;
  align-items: center;
  padding-right: 1.5rem;
  border-right: 2px dashed rgba(255,255,255,0.5);
}

.vessel-list {
  display: flex;
  align-items: center;
  margin-right: auto;
  > div {
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    &:not(:last-of-type) {
      border-right: 2px dashed rgba(255,255,255,0.5);
    }
    > div {
      &:not(:first-child) {
        padding-left: 20px;
      }
    }
  }
  .departure-time {
    margin-right: 1rem;
    font-weight: 700;
  }
  .boarding-time {
    display: none;
  }
}

.TourOperatorsScreen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  .grid {
    display: flex;
    margin: 1rem 0;
    padding-bottom: 1rem;

    &:not(:last-of-type) {
      border-bottom: 2px dashed rgba(225, 167, 59, 0.5);
    }

    &.vessels-grid:first-of-type {
      padding-top: 1rem;
      margin-top: 0;
      border-top: 2px dashed rgba(225, 167, 59, 0.5);
    }

    .col-1 {
      flex: 0 8.3%;
    }
    .col-2 {
      flex: 0 42%;
    }
    .col-3 {
      flex: 0 45.5%;
    }
  }
  /*.grid {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 0px;
      margin: 1rem 0;
      padding-bottom: 1rem;
      &:not(:last-of-type) {
        border-bottom: 2px dashed rgba(225,167,59,0.5);
      }
      &.vessels-grid:first-of-type {
        padding-top: 1rem;
        margin-top: 0;
        border-top: 2px dashed rgba(225,167,59,0.5);
      }
      .column-1 {
        grid-area: 1 / 1 / 2 / 2;
        p {
          font-weight: 700;
        }
      }
      .column-2 { grid-area: 1 / 2 / 2 / 3; }
      .column-3 { grid-area: 1 / 3 / 2 / 8; }
      .column-4 { grid-area: 1 / 8 / 2 / 13; }
  }*/
  .tour-table {
    grid-template-columns: repeat(11, 1fr);
    .col-1 { grid-area: 1 / 1 / 2 / 2; }
    .col-2 { grid-area: 1 / 2 / 2 / 7; }
    .col-3 { grid-area: 1 / 7 / 2 / 12; }
  }
}

.clock-only {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  .clock {
    font-size: 20rem;
    font-weight: 700;
    background-color: transparent;
    color: $yellow;
    padding: 0.75rem 1rem;
    border-radius: 0.3rem;
  }
}